<script>
	export let background = 'bg-white'
	export let smallFlush = false

	let px = 'px-8'
	if (smallFlush) px = 'md:px-8'
</script>
<div class="flex flex-col grow items-center {background}">
	<div class="w-full max-w-tee-content mx-auto py-16 space-y-16 {px}">
		<slot />
	</div>
</div>